import Bowser from 'bowser';
import { UserName } from 'common/graphql/types';
import { addDate, formatDate, getCurrentTimezone, startOfDate, toDate } from 'common/utils/datetime';
import DOMPurify from 'dompurify';
import {
    compact,
    forEach,
    includes,
    isArray,
    isNil,
    join,
    map,
    padStart,
    replace,
    round,
    sortBy,
    split,
    startsWith,
    toLower,
    toNumber,
    toUpper,
    trim,
} from 'lodash';
import { PENDING_MIGRATION_ITEM_LABEL } from './constants';
import { CalculationExec } from '@orion/calculation-engine';
import { CalculatedValues, CalculationErrorType } from 'module/activity/types/types';
import { all, create } from 'mathjs';

export const isIE = (): boolean => {
    const { browser } = Bowser.parse(window.navigator.userAgent);
    return browser?.name === 'Internet Explorer';
};

const convertTimeStringToHours = (timeStringPart: string): number => {
    let numberTimeParts = 0;
    let totalHours = 0;
    for (let i = 0; i < timeStringPart?.length || 0; ++i) {
        const firstChar = timeStringPart.charAt(i);
        if (firstChar === ':') {
            continue;
        } else if (firstChar === '.') {
            totalHours += +timeStringPart.substr(i) / Math.pow(60, --numberTimeParts);
            break;
        } else {
            totalHours += +timeStringPart.substr(i, 2) / Math.pow(60, numberTimeParts);
            ++numberTimeParts;
            ++i;
        }
    }
    return totalHours;
};

const millisToDays = 1000 * 60 * 60 * 24;
export const daysBetween = (moreDistantDate: Date, moreRecentDate: Date): number => {
    const diffMillis = moreRecentDate.getTime() - moreDistantDate.getTime();
    return Math.floor(diffMillis / millisToDays);
};

export const convertAwsTimeStringToDate = (awsTime: string, startDate: Date = new Date()): Date => {
    // (HH):?(mm)?:?(ss.sss)?(Z|[+-](HH):?(mm)?:?(ss)?)?

    let date = startOfDate(startDate, 'day');

    const timeParts = compact(map(split(awsTime, /Z|[+-]/), (s) => trim(s)));
    if (timeParts.length) {
        const hours = convertTimeStringToHours(timeParts[0]);
        date = addDate(hours, 'hours', date);

        const currentOffset = (new Date().getTimezoneOffset() / 60) * -1;
        if (awsTime.indexOf('Z') > 0) {
            date = addDate(currentOffset, 'hours', date);
        } else if (timeParts.length > 1) {
            const offsetDirection = awsTime.lastIndexOf('+') > 0 ? 1 : -1;
            const hoursOffset = convertTimeStringToHours(timeParts[1]) * offsetDirection;
            date = addDate(currentOffset - hoursOffset, 'hours', date);
        }
    }
    return toDate(date);
};

export const convertAwsDateTimeToDate = (awsDateTime: string): Date => {
    return toDate(awsDateTime);
};

export const convertTimeStringToAwsTimeString = (timeString: string): string => {
    const _timeString = trim(timeString);
    if (!_timeString) {
        return '00:00:00.000';
    }

    const awsTimeParts = [0, 0, 0];
    const isPM = includes(toUpper(_timeString.substr(-2)), 'P');

    const timeParts = split(_timeString, ':');
    const numTimeParts = Math.min(timeParts.length, 3);
    for (let i = 0; i < numTimeParts; ++i) {
        awsTimeParts[i] = parseFloat(trim(timeParts[i]));
    }
    const hourValueIs12 = awsTimeParts[0] === 12;
    if (isPM) {
        if (!hourValueIs12) {
            awsTimeParts[0] += 12;
        }
    } else if (hourValueIs12) {
        awsTimeParts[0] = 0;
    }

    awsTimeParts[0] = isNaN(awsTimeParts[0]) ? 0 : awsTimeParts[0] % 24;
    awsTimeParts[1] = isNaN(awsTimeParts[1]) ? 0 : awsTimeParts[1] % 60;
    awsTimeParts[2] = isNaN(awsTimeParts[2]) ? 0 : awsTimeParts[2] % 60;
    const subSeconds = (awsTimeParts[2] % 1).toFixed(3).substr(1);

    return (
        padStart(awsTimeParts[0].toString(), 2, '0') +
        ':' +
        padStart(awsTimeParts[1].toString(), 2, '0') +
        ':' +
        padStart(Math.floor(awsTimeParts[2]).toString(), 2, '0') +
        subSeconds
    );
};

export const containsAny = (str: string, ...compare: string[]): boolean => {
    for (const s of compare || []) {
        // eslint-disable-next-line lodash/prefer-includes
        if (includes(str, s)) {
            return true;
        }
    }
    return false;
};

export const sortByValueArray = <T>(valueArray: string[], itemArray: T[], valueProp: string): T[] => {
    const newSort = sortBy(itemArray, (item: T): number => {
        const index = valueArray.indexOf(item[valueProp]);
        return index < 0 ? itemArray?.length : index;
    });
    return newSort;
};

export const dedupArray = <T>(arr: T[]): T[] => {
    if (isIE()) {
        const dedupedArr: T[] = [];
        forEach(arr || [], (t: T): void => {
            if (!includes(dedupedArr, t)) {
                dedupedArr.push(t);
            }
        });
        return dedupedArr;
    } else {
        return Array.from(new Set<T>(arr || []));
    }
};

export const convertSetToArray = <T>(set: Set<T>): T[] => {
    if (isIE()) {
        const arr: T[] = [];
        // eslint-disable-next-line lodash/prefer-lodash-method
        (set || []).forEach((t: T): void => {
            arr.push(t);
        });
        return arr;
    } else {
        return Array.from(set);
    }
};

export const first = <T>(dict: { [id: string]: T }): T => {
    for (const key in dict) {
        return dict[key];
    }
};

export const generateLocalTimeGql = (date?: string | Date): { timestamp: string; timezone: string } => ({
    timestamp: (date ? new Date(date) : new Date()).toISOString(),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
});

export const copyTextToClipboard = (text: string): Promise<boolean> => {
    if (navigator?.clipboard) {
        return navigator.clipboard
            .writeText(text)
            .then((): boolean => true)
            .catch((): boolean => false);
    } else {
        return new Promise<boolean>((resolve): void => {
            const copyListener = (e: ClipboardEvent): void => {
                e.clipboardData.setData('text/plain', text);
                e.preventDefault();
                document.removeEventListener('copy', copyListener);
            };
            document.addEventListener('copy', copyListener);
            document.execCommand('copy');
            resolve(true);
        });
    }
};

export const stripHtml = (html: string): string => {
    const div = document.createElement('div');
    div.style.display = 'none';
    div.innerHTML = html;
    const root = document.querySelector('#root');
    if (!isNil(root)) {
        root.appendChild(div);
    }
    const text = div.innerText;
    if (!isNil(root)) {
        root.removeChild(div);
    }
    return text;
};

const elevatedUsersTypes = ['MONITOR', 'INSPECTOR'];
export const isUserElevated = (subType: string): boolean => includes(elevatedUsersTypes, subType);

export const getStudyIdFromStudyPk = (studyId: string): string => split(studyId, '#')[1];

export const sanitizeKeepHyperlinks = (value: string): string => {
    // The 'target' attribute must not be sanitized or Froala's option to create a link
    // opening in a new tab doesn't work
    const htmlAttributeAllowlist = ['target'];
    return DOMPurify.sanitize(value, { ADD_ATTR: htmlAttributeAllowlist });
};

export const downloadFile = async (url: string, openInNewWindow: boolean = false, fileName?: string) => {
    const link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a') as HTMLAnchorElement;
    link.rel = 'noopener';

    if (openInNewWindow) {
        link.target = '_blank';
    }
    if (fileName) {
        link.download = fileName;
    }
    link.href = url;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.dispatchEvent(new MouseEvent('click'));

    await sleep(100);
    document.body.removeChild(link);
};

export const swapArrayElements = (arr: unknown[], index1: number, index2: number) => {
    const temp = arr[index1];
    arr[index1] = arr[index2];
    arr[index2] = temp;
};

export const isSafari = (): boolean => {
    return (
        navigator.vendor &&
        includes(navigator.vendor, 'Apple') &&
        navigator.userAgent &&
        !includes(navigator.userAgent, 'CriOS') &&
        !includes(navigator.userAgent, 'FxiOS')
    );
};

export const isFirefox = (): boolean => includes(toLower(window.navigator.userAgent), 'firefox');

export const isInPath = (path: string, rootPath: string): boolean =>
    path === rootPath || startsWith(path, `${rootPath}/`);

/* Temperature converted to Fahrenheit  */
export const toFahrenheitTemperature = (valueInCentigrade: number | string): number => {
    return round((9 / 5) * toNumber(valueInCentigrade), 3) + 32;
};

export const cmToInch = (valueInCm: number | string): number => {
    return toNumber(valueInCm) / 2.54;
};

export const kgToLb = (valueInKg: number | string): number => {
    return toNumber(valueInKg) * 2.20462262185;
};

export const sleep = (timeInMS: number): Promise<boolean> =>
    new Promise((resolve) => {
        setTimeout(resolve, timeInMS);
    });

export const isJson = (str: string): boolean => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const isJsonArray = (value: string) => {
    return isJson(value) && isArray(JSON.parse(value));
};

// -1 if a comes before b, 1 if b comes before a, 0 if they are equivalent
export const stringLocaleCompare = (a: string, b: string) => {
    if (a && b) {
        return a.localeCompare(b);
    }
    if (a && !b) {
        return -1;
    }
    if (!a && b) {
        return 1;
    }
    return 0;
};

const specialEntities = {
    '&#39;': "'",
    '&nbsp;': ' ',
    '&quot;': '"',
    '&amp;': '&',
    '&gt;': '>',
    '&<lt;': '<',
};

export const removeHtmlTags = (html): string => {
    let text = replace(html, /(<([^>]+)>)/gi, '');

    forEach(specialEntities, (value, key) => {
        text = replace(text, new RegExp(key, 'gi'), value);
    });

    return text;
};

export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) {
        return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const runAfterFramePaint = (callback: () => void) => {
    requestAnimationFrame(() => {
        const messageChannel = new MessageChannel();
        messageChannel.port1.onmessage = callback;
        messageChannel.port2.postMessage(undefined);
    });
};

export const getUpdatedAtByInfo = (updatedAtString: string, updatedByName: UserName) => {
    const updatedAt = formatDate('DD MMM YYYY, HH:mm', updatedAtString, null, getCurrentTimezone());

    const { firstName, lastName, username } = updatedByName ?? {};
    let updatedBy: string;
    if (firstName && lastName) {
        updatedBy = `${firstName} ${lastName}`;
    } else if (username) {
        updatedBy = username;
    } else {
        updatedBy = PENDING_MIGRATION_ITEM_LABEL;
    }
    return { updatedAt, updatedBy };
};

export const incrementMinorVersion = (version: string): string => {
    if (!version || version === '.NaN') {
        return version;
    }
    const versionParts = split(version, '.');
    const minor = parseInt(versionParts[1]);
    return join([versionParts[0], minor + 1], '.');
};

export const incrementMajorVersion = (version: string): string => {
    if (!version || version === '.NaN') {
        return version;
    }
    const versionParts = split(version, '.');
    const major = parseInt(versionParts[0]);
    return join([major + 1, 0], '.');
};

export const executeCalculation = (
    calculatedValue: Partial<CalculatedValues>,
    formula: string = calculatedValue.code
): string => {
    const mathjs = create(all, {});
    let dummyData = {};
    let errorMessage = '';

    forEach(calculatedValue.variableMap, (variable) => {
        dummyData[variable.questionId] = {
            answers: [
                {
                    id: '',
                    type: 'INT',
                    value: '1',
                    valueInt: 1,
                    valueCoding: 1,
                    codedSelection: 1,
                    text: '',
                    enText: '',
                },
            ],
        };
    });

    try {
        CalculationExec(formula, {
            data: dummyData,
            variableMap: calculatedValue.variableMap,
            libraries: { mathjs },
        });
    } catch (e) {
        // we are not interested in Data errors
        if (e.name === CalculationErrorType.DATA) {
            return;
        }
        if (e.location) {
            const line = e.location.start.line;
            const column = e.location.start.column;
            errorMessage = `Line ${line}, column ${column}: ${e.message}`;
        } else {
            errorMessage = e.message;
        }
    } finally {
        return errorMessage;
    }
};

export const parseVersionString = (version: string) => {
    if (!version || version === '.NaN') {
        return;
    }

    const versionParts = split(version, '.');
    const major = parseInt(versionParts[0]);
    const minor = parseInt(versionParts[1]);
    return { major, minor };
};
