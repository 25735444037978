import { ApolloClient, gql } from '@apollo/client';
import { Capacitor } from '@capacitor/core';
import * as AWSCognito from 'amazon-cognito-identity-js';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import gqlClient, { getCognitoUser, nonAuthClient, resetLastAuthUser } from 'common/graphql/client';
import { setLanguage } from 'common/i18n/i18n';
import { AuditCreationEventsEnum, AuditEntityIdEnum } from 'common/log/auditevents';
import { CIAuditLogger } from 'common/log/ci.auditlogger';
import { NativeDeviceInitializer } from 'common/native-app-support/device-initializer/native.device.initializer';
import Sentry from 'common/sentry/sentry';
import { setBlockLogout } from 'common/utils/block-logout.utils';
import { OrionCookies, getCookie, setCookie } from 'common/utils/cookies';
import { dateToISOString, toDate } from 'common/utils/datetime';
import { patientIdSelectionStorage } from 'common/utils/patientIdSelectionStorage';
import { isSponsorUser } from 'common/utils/sponsorUtils';
import { createHmac } from 'crypto-browserify';
import { produce } from 'immer';
import { jwtDecode } from 'jwt-decode';
import {
    cloneDeep,
    find,
    get,
    includes,
    isEmpty,
    isFunction,
    isNil,
    isUndefined,
    map,
    set,
    split,
    startsWith,
    toLower,
    trim,
} from 'lodash';
import { updateEconsentSignature } from 'module/econsent/utils/endpoints';
import { createLogic } from 'redux-logic';
import { ArgumentAction } from 'redux-logic/definitions/action';
import { v4 as uuid } from 'uuid';
import { CIAnalytics } from '../analytics/analytics.provider';
import { CIError } from '../error/error_util';
import {
    AuthUserType,
    Caregiver,
    GetPatientDataQuery,
    GetUserPermissionsAndStudySitesQuery,
    PublicKey,
    RoleCategoryEnum,
    RoleTypeEnum,
    RoleVariantEnum,
    UserGuidesStatus,
    UserLastPage,
    UserStatusEnum,
    UserStudies,
    UserStudySite,
    UserSubType,
    UserType,
} from '../graphql/types';
import { CILogger } from '../log/log.provider';
import { NativeAuthManager } from '../native-app-support/native.auth.manager';
import { OfflineSyncPlugin } from './../native-app-support/ci-native-plugins/CIPlugins';
import { NativeErrorCode } from './../native-app-support/native.errorcode';
import { OfflineManager } from './../native-app-support/native.offline.manager';
import { oktaSLO } from './sso/okta.utils';
import { getOktaUserPool } from './sso/oktaUserPool';
import { SsoAccessTokenData, SsoIdTokenData, SsoSessionKeys } from './sso/sso.types';

export const getUserPermissionsAndStudySites = `query getUserPermissionsAndStudySites($pk:String) {
    getUser(pk:$pk, sk:"User"){
        name
        pk
        firstName
        lastName
        sk
        tn
        status
        acceptedVersionPP
        subType
        type
        parentId
        roleName
        createdAt
        email
        securityQuestion1Text
        securityQuestion2Text
        securityQuestion3Text
        isDeleted
        version
        dateOfLastPasswordChange
        guidesStatus {
            pk
            sk
            isCompleted
            guideName
            updatedAt
        }
        publicKeys {
            deviceId
            key
        }
        roleBClaim {
          tn
          data
          name
          schema
          sk
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        roleVClaim {
            tn
            data
            name
            sk
            schema
            createdAt
            createdBy
            updatedAt
            updatedBy
        }
        lastPagesOpened {
            url
            studyId
            siteId
        }
        userStudiesRaw {
            pk sk data tn name version studyName userId studyId
            userStudySitesRaw {
              pk sk data tn name version siteName userId studyId siteId siteIdentifier
            }
        }
        preferredTimezone
    }
    getPermissionsByUser(userId:$pk) {
        items
    }    
}`;

export const GetPatientData = `
    query GetPatientDataQuery($pk:String!) {
        getPatient(pk:$pk) {
            name
            pk
            sk
            tn
            firstName
            lastName
            email
            type
            subType
            userStatus
            initials
            version
            isDeleted
            securityQuestion1Text
            securityQuestion2Text
            securityQuestion3Text
            acceptedVersionPP
            screeningIdentifier
            createdAt
            parentId
            studyInstanceId
            armId
            studyId
            siteId
            status
            firstDay
            patientIdentifier
            preferredLanguage
            preferredTimezone
            activationDate
            caregivers {
                pk
                caregiverNumber
                caregiverRelationshipId
                caregiverNumber
                activationCode
                isLAR
                patientICareForId
                isPrimaryCaregiver
            }
        }
        getUser(pk: $pk, sk: "User") {
            roleBClaim {
                tn
                data
                name
                schema
                sk
                createdAt
                createdBy
                updatedAt
                updatedBy
            }
            roleVClaim {
                tn
                data
                name
                sk
                schema
                createdAt
                createdBy
                updatedAt
                updatedBy
            }
            dateOfLastPasswordChange
            roleName
            status
        }  
        getPermissionsByUser(userId:$pk) {
            items
        }
    }
`;

export const updateUserLastPasswordChanged = gql`
    mutation updateUser(
        $pk: String!
        $sk: String
        $isDeleted: Int!
        $expectedVersion: Int!
        $dateOfLastPasswordChange: AWSDateTime
    ) {
        updateUser(
            input: {
                pk: $pk
                sk: $sk
                isDeleted: $isDeleted
                expectedVersion: $expectedVersion
                dateOfLastPasswordChange: $dateOfLastPasswordChange
            }
        ) {
            pk
            sk
            version
            dateOfLastPasswordChange
        }
    }
`;

const getCurrentPolicyAPI = gql`
    query listSubPP($pk: String!, $filter: SearchableSubscriberPPFilterInput) {
        listSubscriberPPs(pk: $pk, filter: $filter) {
            items {
                pk
                sk
                language
                majorVersion
                version
                versionStr
                updatedAt
                updatedByName {
                    firstName
                    lastName
                    username
                }
            }
        }
    }
`;

interface GetSubscriberResponse {
    getSubscriber: {
        allowDcf: boolean;
    };
}

const getSubscriberQuery = gql`
    query getsubscriber($pk: String!) {
        getSubscriber(pk: $pk) {
            allowDcf
        }
    }
`;

const updateUserToSPP = gql`
    mutation upUserTOSPP($userId: String!, $pVersion: String!) {
        updateUserTOSandPP(userId: $userId, ppVersion: $pVersion)
    }
`;

const deleteLastPagesOpenedQuery = gql`
    mutation deleteUserLastPages($pk: String!) {
        updateUserLastPages(userId: $pk, lastPagesOpened: []) {
            url
            studyId
            siteId
        }
    }
`;

const updateUserLastActiveDate = gql`
    mutation updateUserLastActiveDate {
        updateUserLastActiveDate
    }
`;

const markPwdQuery = gql`
    mutation markPassword($pk: String!, $pwd: String!) {
        markPasswordUsedForUser(userId: $pk, passwdHash: $pwd)
    }
`;

const checkPwdAvailableQuery = gql`
    query checkPassword($pk: String!, $pwd: String!, $activationCode: String) {
        checkPasswordAvailabile(userId: $pk, passwdHash: $pwd, activationCode: $activationCode)
    }
`;

export const getRemainingAttempts = gql`
    query getUserNumberOfLoginTriesBeforeLockout($userId: String!) {
        getUserNumberOfLoginTriesBeforeLockout(userId: $userId)
    }
`;

const getStudySites = gql`
    query getStudySites($studyId: String!) {
        getStudy(pk: $studyId) {
            sites {
                pk
                sk
                displayName
                data
                version
                isDeleted
                siteIdentifier
            }
        }
    }
`;

export interface AuthUser {
    currentPolicy: number;
    pk: string;
    sk: string;
    tn: string;
    name: string;
    parentId: string;
    subscriberPk: string;
    sponsorPk?: string;
    customerPk?: string;
    userType: string;
    permGroups: string[];
    username: string;
    firstName: string;
    lastName: string;
    type: string;
    email: string;
    firstDay?: string;
    createdAt?: Date;
    initials: string;
    permissions: string[];
    impersonating: AuthUser;
    sessionId: string;
    authenticationMethod: 'COGNITO' | 'OKTA';
    acceptedVersionPP: number;
    subType: UserSubType;
    dateOfLastPasswordChange: Date;
    isDeleted: number;
    version: number;
    roleBClaim?: {
        data: string;
        sk: string;
    };
    roleVClaim?: {
        name: string;
        sk: string;
    };
    armId?: string;
    screeningIdentifier?: string;
    studyInstanceId?: string;
    studyId?: string;
    userStudies?: UserStudies[];
    guidesStatus?: UserGuidesStatus[];
    auth: {
        idToken: string;
        refreshToken: string;
        accessToken: string;
        expiry: number;
        iss?: string;
    };
    isValidSwitchUser?: boolean; //Temp data to hold validity between redux dispatches
    securityQuestion1Text?: string;
    securityQuestion2Text?: string;
    securityQuestion3Text?: string;
    isSecurityQuestionSetUp?: boolean;
    role?: string;
    status?: UserStatusEnum;
    preferredLanguage?: string;
    lastPagesOpened?: UserLastPage[];
    publicKeys: PublicKey[];
    subscriberDetails: {
        allowDcf: boolean;
    };
    preferredTimezone: string;
    activationDate: string;
    caregivers?: Caregiver[];
}

function persistAuthUsers(state): void {
    /*
        TODO: Impersonation : 
        TODO : updateActiveStudySite() as well for Impersonation
        For impersonation, we need to have a centralized storage for authenticated user(s) that gets shared between tabs
        and specifics on the user being impersonated stored in a way it's only available on that tab.  When opening a new
        tab for impersonation reasons, we need to be able to initialize that browser tab state from the URL
     */
    localStorage.setItem('activeUser', JSON.stringify(state.activeUser));
    sessionStorage.setItem('activeStudySite', JSON.stringify(state.activeStudySite));
    sessionStorage.setItem('showToastMsg', 'true');
    try {
        Sentry?.setUser({ id: state.activeUser?.pk, username: state.activeUser?.username }); //adding user pk to the id if active user is present
    } catch (err) {
        Sentry?.captureException(err);
    }
}

function updateActiveStudySite(state): void {
    if (state.activeStudySite !== null) {
        sessionStorage.setItem('activeStudySite', JSON.stringify(state.activeStudySite));
    }
}

let cognitoUser;

function updateAuthUsers(state, switchingUser = false): void {
    //TODO: to be handled in multi user scenario
    if (state.activeUser !== null) {
        localStorage.setItem('activeUser', JSON.stringify(state.activeUser));
    } else {
        window.location.href = '/'; // added redirection when there is no user to avoid navigating to last browser history page
        localStorage.removeItem('activeUser');
        sessionStorage.removeItem('activeStudySite');
        localStorage.removeItem('switchingUser'); //Switch User
        localStorage.removeItem('patientAccess'); //Patient Access
        patientIdSelectionStorage.remove();
    }
    if (!switchingUser) {
        setBlockLogout(false);
    }
}

function updateSwitchUserState(state): void {
    if (state.switchingUser !== null) {
        localStorage.setItem('switchingUser', JSON.stringify(state.switchingUser));
    }
    if (state.patientAccess) {
        localStorage.setItem('patientAccess', JSON.stringify(state.patientAccess));
    }
}

export enum AUTH_STAGE {
    NEW_PASS_REQUIRED = 'NEW_PASS_REQUIRED',
    MFA_REQUIRED = 'MFA_REQUIRED',
    TOTP_REQUIRED = 'TOTP_REQUIRED',
    CUSTOM_CHALLENGE = 'CUSTOM_CHALLENGE',
    MFA_SETUP = 'MFA_SETUP',
    SELECT_MFA_TYPE = 'SELECT_MFA_TYPE',
}

export function buildAuthUserFromCognitoSession(session: CognitoUserSession): AuthUser {
    let userKeyToken = session.getIdToken().decodePayload().UserId;
    let idToken = session.getIdToken().decodePayload();
    let accessToken = session.getAccessToken().decodePayload();

    return {
        pk: split(userKeyToken, ':')[1],
        sk: null,
        tn: 'User',
        sessionId: uuid(),
        authenticationMethod: 'COGNITO',
        subscriberPk: idToken['custom:SubscriberId'],
        userType: idToken['custom:UserType'],
        name: null,
        permGroups: [],
        parentId: null,
        firstName: null,
        firstDay: null,
        type: null,
        role: null,
        lastName: null,
        email: idToken.email,
        initials: null,
        username: accessToken.username,
        permissions: [],
        impersonating: null,
        acceptedVersionPP: null,
        currentPolicy: null,
        subType: idToken['custom:SubType'],
        userStudies: [],
        studyId: null,
        isDeleted: 0,
        version: 0,
        dateOfLastPasswordChange: null,
        guidesStatus: null,
        auth: {
            accessToken: session.getAccessToken().getJwtToken(),
            idToken: session.getIdToken().getJwtToken(),
            refreshToken: session.getRefreshToken().getToken(),
            expiry: session.getIdToken().getExpiration(),
        },
        publicKeys: [],
        subscriberDetails: {
            allowDcf: true,
        },
        preferredTimezone: 'America/New_York', // default timezone is set America
        activationDate: null,
    };
}

export function buildAuthUserFromSSOAccessToken(ssoTokens: SsoSessionKeys): AuthUser {
    const { accessToken, idToken, refreshToken } = ssoTokens;

    const accessTokenDecoded = accessToken && jwtDecode<SsoAccessTokenData>(accessToken);
    const idTokenDecoded = idToken && jwtDecode<SsoIdTokenData>(idToken);

    setCookie(OrionCookies.oktaLogout, 'true', 500);

    const authUser: AuthUser = {
        pk: idTokenDecoded?.['custom:UserId'] || accessTokenDecoded?.username || idTokenDecoded?.preferred_username,
        sk: 'User',
        tn: 'User',
        sessionId: uuid(),
        authenticationMethod: 'OKTA',
        subscriberPk: idTokenDecoded?.['custom:SubscriberId'],
        userType: idTokenDecoded?.['custom:UserType'],
        name: null,
        permGroups: [],
        parentId: idTokenDecoded?.['custom:UserParentId'],
        firstName: null,
        firstDay: null,
        type: null,
        role: null,
        lastName: null,
        email: idTokenDecoded?.email,
        initials: null,
        username: accessTokenDecoded?.username || idTokenDecoded?.preferred_username,
        permissions: [],
        impersonating: null,
        acceptedVersionPP: null,
        currentPolicy: null,
        subType: null,
        userStudies: [],
        isDeleted: 0,
        version: 0,
        dateOfLastPasswordChange: null,
        guidesStatus: null,
        preferredTimezone: 'America/New_York',
        activationDate: null,
        auth: {
            accessToken,
            idToken,
            refreshToken,
            expiry: idTokenDecoded?.exp,
            iss: idTokenDecoded?.iss,
        },
        publicKeys: [],
        subscriberDetails: {
            allowDcf: true,
        },
    };

    return authUser;
}

export enum CognitoAuthErrorCode {
    NETWORK_ERROR = 'NetworkError',
    INTERNAL_ERROR = 'InternalErrorException',
    INVALID_LAMBDA = 'InvalidLambdaException',
    INVALID_PARAMETER = 'InvalidParameterException',
    INVALID_USER_POOL_CONFIGURATION = 'InvalidUserPoolConfigurationException',
    NOT_AUTHORIZED = 'NotAuthorizedException',
    PASSWORD_RESET_REQUIRED = 'PasswordResetRequiredException',
    RESOURCE_NOT_FOUND = 'ResourceNotFoundException',
    TOO_MANY_REQUESTS = 'TooManyRequestsException',
    UNEXPECTED_LAMBDA = 'UnexpectedLambdaException',
    USER_LAMBDA_VALIDATION = 'UserLambdaValidationException',
    USER_NOT_CONFIRMED = 'UserNotConfirmedException',
    USER_NOT_FOUND = 'UserNotFoundException',
    PASSWORD_UNAVAILABLE = 'PasswordUnavailable',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getGraphQLClient(): ApolloClient<any> {
    return gqlClient;
}

const oktaUserPool = getOktaUserPool();

const createHMACPaswordHash = (pass): string => createHmac('sha256', 'clinicalink').update(pass).digest('hex');

function checkPasswordAvailability(userPk, pwd, isActivationCode): Promise<boolean> {
    try {
        const activationCode = sessionStorage.getItem('actCode');
        //for forgot password, reset password flow with security questions
        if (activationCode && isActivationCode) {
            return (
                nonAuthClient
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .query<any>({
                        query: gql`
                            ${checkPwdAvailableQuery}
                        `,
                        variables: {
                            pk: userPk,
                            pwd: createHMACPaswordHash(pwd),
                            activationCode: activationCode,
                        },
                        fetchPolicy: 'network-only',
                    })
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .then((response): any => {
                        return response.data.checkPasswordAvailabile;
                    })
                    .catch((err): void => {
                        throw CIError.fromGraphQLError(err);
                    })
            );
        } else if (isActivationCode) {
            // for new user activation flow, where activation code is not saved in session
            return new Promise((resolve) => {
                resolve(true);
            });
        } else {
            // for profile page password change flow
            return (
                getGraphQLClient()
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .query<any>({
                        query: gql`
                            ${checkPwdAvailableQuery}
                        `,
                        variables: {
                            pk: userPk,
                            pwd: createHMACPaswordHash(pwd),
                        },
                        fetchPolicy: 'network-only',
                    })
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .then((response): any => {
                        return response.data.checkPasswordAvailabile;
                    })
                    .catch((err): void => {
                        throw CIError.fromGraphQLError(err);
                    })
            );
        }
    } catch (err) {
        throw CIError.fromGraphQLError(err);
    }
}
function markPasswordUsed(userPk: string, newPass: string): Promise<boolean> {
    //remove the activation code from session if added on password reset flow
    if (sessionStorage.getItem('actCode')) {
        sessionStorage.removeItem('actCode');
    }
    try {
        return (
            getGraphQLClient()
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .query<any>({
                    query: gql`
                        ${markPwdQuery}
                    `,
                    variables: {
                        pk: userPk,
                        pwd: createHMACPaswordHash(newPass),
                    },
                    fetchPolicy: 'no-cache',
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .then((response): any => {
                    return response;
                })
                .catch((err): void => {
                    CILogger.getInstance().warn(err);
                    throw CIError.fromGraphQLError(err);
                })
        );
    } catch (err) {
        throw CIError.fromGraphQLError(err);
    }
}

/**
 * Handles Password update for current active User
 *
 * @param oldpass Previous Password
 * @param newpass Proposed Password
 *
 * @returns Current session
 */
function cognitoUserChangePassword(oldpass, newpass: string): Promise<CognitoUserSession> {
    return new Promise((resolve, reject): void => {
        checkPasswordAvailability(cognitoUser.username, newpass, false)
            .then((passwordAvailable) => {
                if (passwordAvailable) {
                    cognitoUser.changePassword(oldpass, newpass, function (e, result) {
                        if (e) {
                            if (
                                (e.code !== CognitoAuthErrorCode.NETWORK_ERROR &&
                                    e.code !== CognitoAuthErrorCode.NOT_AUTHORIZED &&
                                    e.code !== CognitoAuthErrorCode.PASSWORD_RESET_REQUIRED &&
                                    e.code !== CognitoAuthErrorCode.TOO_MANY_REQUESTS &&
                                    e.code !== CognitoAuthErrorCode.USER_NOT_CONFIRMED &&
                                    e.code !== CognitoAuthErrorCode.USER_NOT_FOUND) ||
                                !e.code
                            ) {
                                CILogger.getInstance().warn(e);
                            } else {
                                CILogger.getInstance().info(e);
                            }

                            e.code = e.code || CognitoAuthErrorCode.INVALID_PARAMETER;

                            reject(CIError.fromCognitoError(e));
                        }
                        if (result === 'SUCCESS') {
                            cognitoUser.getSession(function (err, session) {
                                if (err) {
                                    reject(CIError.fromCognitoError(err));
                                } else {
                                    resolve(session);
                                }
                            });
                        }
                    });
                } else {
                    const err = {
                        code: CognitoAuthErrorCode.PASSWORD_UNAVAILABLE,
                        name: null,
                        statusCode: null,
                        message: `cognito.${CognitoAuthErrorCode.PASSWORD_UNAVAILABLE}`,
                    };
                    reject(CIError.fromCognitoError(err));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}
function cognitoChangePassword(
    newpass: string,
    dispatchAction?: (action: ArgumentAction<string, undefined, undefined>) => void
): Promise<CognitoUserSession> {
    return new Promise((resolve, reject): void => {
        checkPasswordAvailability(cognitoUser?.username, newpass, true)
            .then((passwordAvailable) => {
                if (passwordAvailable) {
                    const savedSession = sessionStorage.getItem('sessionId');
                    if (!cognitoUser.Session && savedSession) {
                        cognitoUser.Session = savedSession;
                    }
                    sessionStorage.removeItem('sessionId');
                    if (isFunction(dispatchAction)) {
                        dispatchAction(AuthActions.isNewPasswordRequested(false));
                    }
                    cognitoUser.completeNewPasswordChallenge(newpass, null, {
                        onSuccess: (session: CognitoUserSession): void => {
                            resolve(session);
                        },
                        onFailure: (e): void => {
                            if (
                                (e.code !== CognitoAuthErrorCode.NETWORK_ERROR &&
                                    e.code !== CognitoAuthErrorCode.NOT_AUTHORIZED &&
                                    e.code !== CognitoAuthErrorCode.PASSWORD_RESET_REQUIRED &&
                                    e.code !== CognitoAuthErrorCode.TOO_MANY_REQUESTS &&
                                    e.code !== CognitoAuthErrorCode.USER_NOT_CONFIRMED &&
                                    e.code !== CognitoAuthErrorCode.USER_NOT_FOUND) ||
                                !e.code
                            ) {
                                CILogger.getInstance().warn(e);
                            } else {
                                CILogger.getInstance().info(e);
                            }

                            e.code = e.code || CognitoAuthErrorCode.INVALID_PARAMETER;

                            reject(CIError.fromCognitoError(e));
                        },
                        mfaRequired: (e): void => {
                            reject({ ...e, trigger: AUTH_STAGE.MFA_REQUIRED });
                        },
                        customChallenge: (e): void => {
                            reject({ ...e, trigger: AUTH_STAGE.CUSTOM_CHALLENGE });
                        },
                        mfaSetup: (e): void => {
                            reject({ ...e, trigger: AUTH_STAGE.MFA_SETUP });
                        },
                    });
                } else {
                    const err = {
                        code: CognitoAuthErrorCode.PASSWORD_UNAVAILABLE,
                        name: null,
                        statusCode: null,
                        message: `cognito.${CognitoAuthErrorCode.PASSWORD_UNAVAILABLE}`,
                    };
                    reject(CIError.fromCognitoError(err));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export interface CognitoAuthError {
    code: CognitoAuthErrorCode;
    name: string;
    statusCode: number; // Not available for most Cognito error types, set to response.status along with the message field so should be able to always ignore this field
    message: string;
}

let poolData = {
    UserPoolId: process.env.REACT_APP_AWS_COGNITO_USERPOOL_ID,
    ClientId: process.env.REACT_APP_AWS_COGNITO_USERPOOL_CLIENTID,
    Storage: window.localStorage,
};

export function authenticateViaCognito(
    username: string,
    password: string,
    dispatchAction?: (action: ArgumentAction<string, undefined, undefined>) => void
): Promise<CognitoUserSession> {
    const formattedUsername = toLower(trim(username));
    const formattedPassword = trim(password);

    let userPool = new AWSCognito.CognitoUserPool(poolData);
    let authenticationData = { Username: formattedUsername, Password: formattedPassword };
    let authenticationDetails = new AWSCognito.AuthenticationDetails(authenticationData);
    cognitoUser = new AWSCognito.CognitoUser({
        Username: formattedUsername,
        Pool: userPool,
        Storage: window.localStorage,
    });

    return new Promise((resolve, reject): void => {
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (session): void => {
                resolve(session);
            },
            onFailure: (e: CognitoAuthError): void => {
                // Valid system error conditions should NOT send an error or warning to our logging service

                if (
                    e.code !== CognitoAuthErrorCode.NETWORK_ERROR &&
                    e.code !== CognitoAuthErrorCode.NOT_AUTHORIZED &&
                    e.code !== CognitoAuthErrorCode.PASSWORD_RESET_REQUIRED &&
                    e.code !== CognitoAuthErrorCode.TOO_MANY_REQUESTS &&
                    e.code !== CognitoAuthErrorCode.USER_NOT_CONFIRMED &&
                    e.code !== CognitoAuthErrorCode.USER_NOT_FOUND
                ) {
                    CILogger.getInstance().warn(e);
                } else {
                    CILogger.getInstance().info(e, { username: authenticationDetails.getUsername() });
                }

                reject(CIError.fromCognitoError(e));
            },
            newPasswordRequired: (e): void => {
                sessionStorage.setItem('sessionId', cognitoUser.Session);
                if (isFunction(dispatchAction)) {
                    dispatchAction(AuthActions.isNewPasswordRequested(true));
                }
                reject({ ...e, trigger: AUTH_STAGE.NEW_PASS_REQUIRED });
            },
            mfaRequired: (e): void => {
                reject({ ...e, trigger: AUTH_STAGE.MFA_REQUIRED });
            },
            totpRequired: (e): void => {
                reject({ ...e, trigger: AUTH_STAGE.TOTP_REQUIRED });
            },
            customChallenge: (e): void => {
                reject({ ...e, trigger: AUTH_STAGE.CUSTOM_CHALLENGE });
            },
            mfaSetup: (e): void => {
                reject({ ...e, trigger: AUTH_STAGE.MFA_SETUP });
            },
            selectMFAType: (e): void => {
                reject({ ...e, trigger: AUTH_STAGE.SELECT_MFA_TYPE });
            },
        });
    });
}

function mapGraphQLToUser(result: GetUserPermissionsAndStudySitesQuery, user: AuthUser): AuthUser {
    const {
        pk,
        tn,
        sk,
        name,
        firstName,
        lastName,
        parentId,
        email,
        subType,
        firstDay,
        roleBClaim,
        roleVClaim,
        createdAt,
        type,
        securityQuestion1Text,
        securityQuestion2Text,
        securityQuestion3Text,
        isDeleted,
        version,
        dateOfLastPasswordChange,
        lastPagesOpened,
        guidesStatus,
        userStudiesRaw,
        publicKeys,
        preferredTimezone,
    } = result.getUser;
    user.pk = pk;
    user.tn = tn;
    user.sk = sk;
    user.name = name;
    user.guidesStatus = guidesStatus;
    user.parentId = parentId;
    user.isDeleted = isDeleted;
    user.version = version;
    user.username = name;
    user.firstName = firstName;
    user.lastName = lastName;
    user.firstDay = firstDay;
    user.email = email;
    user.initials = (firstName ? firstName.charAt(0) : '') + (lastName ? lastName.charAt(0) : '');
    user.roleBClaim = roleBClaim;
    user.roleVClaim = roleVClaim;
    user.createdAt = createdAt;
    user.type = type;
    user.permissions = result.getPermissionsByUser.items;
    user.acceptedVersionPP = result.getUser.acceptedVersionPP;
    user.subType = subType;
    user.userStudies = map(
        userStudiesRaw,
        (userStudy: UserStudies): UserStudies => ({
            ...userStudy,
            userStudySites: userStudy?.userStudySitesRaw || [],
        })
    );
    user.securityQuestion1Text = securityQuestion1Text;
    user.securityQuestion2Text = securityQuestion2Text;
    user.securityQuestion3Text = securityQuestion3Text;
    user.dateOfLastPasswordChange = !isNil(dateOfLastPasswordChange) ? toDate(dateOfLastPasswordChange) : null;
    user.role = type === 'System' ? RoleVariantEnum.SystemAdminstrator : null;
    user.lastPagesOpened = lastPagesOpened;
    user.publicKeys = publicKeys;
    user.preferredTimezone = preferredTimezone;

    const parentIdParts = split(parentId, '#');
    for (const parentIdPart of parentIdParts || []) {
        const parentPartLc = toLower(parentIdPart);
        if (startsWith(parentPartLc, 'subscriber.')) {
            user.subscriberPk = parentIdPart;
        }
        if (startsWith(parentPartLc, 'sponsor.')) {
            user.sponsorPk = parentIdPart;
        }
        if (startsWith(parentPartLc, 'customer.')) {
            user.customerPk = parentIdPart;
        }
    }

    return user;
}

function mapGraphQLToPatient(result: GetPatientDataQuery, user: AuthUser): AuthUser {
    const {
        pk,
        sk,
        tn,
        name,
        firstName,
        lastName,
        firstDay,
        parentId,
        email,
        type,
        subType,
        initials,
        version,
        isDeleted,
        securityQuestion1Text,
        securityQuestion2Text,
        securityQuestion3Text,
        acceptedVersionPP,
        screeningIdentifier,
        createdAt,
        armId,
        studyInstanceId,
        studyId,
        siteId,
        preferredLanguage,
        preferredTimezone,
        activationDate,
        caregivers,
    } = result.getPatient;
    const { roleBClaim, roleVClaim, dateOfLastPasswordChange, status, roleName } = result.getUser;
    user.pk = pk;
    user.tn = tn;
    user.sk = sk;
    user.username = name;
    user.email = email;
    user.parentId = parentId;
    user.type = type;
    user.subType = subType;
    user.firstName = firstName;
    user.lastName = lastName;
    user.initials = initials;
    user.isDeleted = isDeleted;
    user.version = version;
    user.firstDay = firstDay;
    user.securityQuestion1Text = securityQuestion1Text;
    user.securityQuestion2Text = securityQuestion2Text;
    user.securityQuestion3Text = securityQuestion3Text;
    user.acceptedVersionPP = acceptedVersionPP;
    user.screeningIdentifier = screeningIdentifier;
    user.createdAt = createdAt as Date;
    user.status = status;
    user.armId = armId;
    user.studyId = studyId;
    user.studyInstanceId = studyInstanceId;
    user.userStudies.push({ studyId: studyId, userStudySites: [{ siteId: siteId }] });
    user.roleBClaim = roleBClaim;
    user.roleVClaim = roleVClaim;
    user.isValidSwitchUser = false;
    user.impersonating = null;
    user.permissions = result.getPermissionsByUser.items;
    user.acceptedVersionPP = acceptedVersionPP;
    user.dateOfLastPasswordChange = dateOfLastPasswordChange;
    user.role = roleName;
    user.preferredLanguage = preferredLanguage;
    user.preferredTimezone = preferredTimezone;
    user.activationDate = activationDate as string;
    user.caregivers = caregivers;
    return user;
}

//get the currently published policy
function getCurrentPolicy(user: AuthUser): Promise<AuthUser> {
    try {
        const authUser = user;
        return (
            getGraphQLClient()
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .query<any>({
                    query: gql`
                        ${getCurrentPolicyAPI}
                    `,
                    variables: {
                        pk: user.subscriberPk,
                        filter: { status: { eq: 'PUBLISHED' }, isDeleted: { eq: 0 }, language: { eq: 'en-US' } },
                    },
                    fetchPolicy: 'no-cache',
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .then((response): any => {
                    return { ...authUser, currentPolicy: response.data.listSubscriberPPs.items };
                })
                .catch((err): void => {
                    throw CIError.fromGraphQLError(err);
                })
        );
    } catch (err) {
        throw CIError.fromGraphQLError(err);
    }
}

/**
 * Gets subscriber details
 * @param authUser
 * @returns AuthUser with Subscriber details
 */
function getSubscriberDetails(authUser: AuthUser): Promise<AuthUser> {
    try {
        return (
            getGraphQLClient()
                .query<GetSubscriberResponse>({
                    query: getSubscriberQuery,
                    variables: {
                        pk: authUser.subscriberPk,
                    },
                    fetchPolicy: 'network-only',
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .then((response): any => {
                    const authUserWithSubscriberDetails: AuthUser = {
                        ...authUser,
                        subscriberDetails: {
                            allowDcf: response.data.getSubscriber.allowDcf,
                        },
                    };
                    return authUserWithSubscriberDetails;
                })
                .catch((err): void => {
                    throw CIError.fromGraphQLError(err);
                })
        );
    } catch (err) {
        throw CIError.fromGraphQLError(err);
    }
}

export function fillUserRecord(user: AuthUser): Promise<AuthUser> {
    try {
        if (user.userType === AuthUserType.PATIENT) {
            return getGraphQLClient()
                .query({
                    query: gql`
                        ${GetPatientData}
                    `,
                    variables: { pk: user.pk || user.username },
                    fetchPolicy: 'network-only',
                })
                .then(async (result): Promise<AuthUser> => {
                    if (user.subscriberPk) {
                        return mapGraphQLToPatient(result.data, await getCurrentPolicy(user));
                    } else {
                        // for user with no subscriber id will not have any privacy policy attached
                        return mapGraphQLToPatient(result.data, user);
                    }
                })
                .catch((err): AuthUser => {
                    throw CIError.fromGraphQLError(err);
                });
        } else {
            const userId = user.authenticationMethod !== 'OKTA' ? user.pk || user.username : undefined;
            return getGraphQLClient()
                .query({
                    query: gql`
                        ${getUserPermissionsAndStudySites}
                    `,
                    variables: { pk: userId },
                    fetchPolicy: 'network-only',
                    context: {
                        generateFields: ['userStudySitesRaw', 'userStudiesRaw'],
                    },
                })
                .then(async (result): Promise<AuthUser> => {
                    const userData = cloneDeep(result);
                    // If Sponsor user(Monitor and Clinical Trial Lead) have no userStudySites assigned treat it as all the sites are assigned by converting all the study.sites to  userStudy.userStudySites
                    if (userData.data.getUser.type === RoleCategoryEnum.Sponsor) {
                        await Promise.all(
                            map(userData.data.getUser.userStudiesRaw, async (userStudy: UserStudies) => {
                                if (isEmpty(userStudy.userStudySitesRaw)) {
                                    try {
                                        const sitesResult = await getGraphQLClient().query({
                                            query: getStudySites,
                                            variables: { studyId: userStudy.studyId },
                                            fetchPolicy: 'network-only',
                                        });
                                        let sites = sitesResult.data?.getStudy?.sites;
                                        sites = map(sites, (site) => {
                                            return {
                                                ...site,
                                                pk: site.sk,
                                                siteId: site.sk,
                                                siteName: site.displayName,
                                            };
                                        });
                                        userStudy.userStudySitesRaw = sites;
                                    } catch (err) {
                                        throw CIError.fromGraphQLError(err);
                                    }
                                }
                            })
                        );
                    }

                    if (user.subscriberPk) {
                        const userWithSubscriberDetails = await getSubscriberDetails(user);
                        return mapGraphQLToUser(userData.data, await getCurrentPolicy(userWithSubscriberDetails));
                    } else {
                        // for user with no subscriber id will not have any privacy policy attached
                        return mapGraphQLToUser(userData.data, user);
                    }
                })
                .catch((err): AuthUser => {
                    throw CIError.fromGraphQLError(err);
                });
        }
    } catch (err) {
        throw CIError.fromGraphQLError(err);
    }
}

async function performNativeAuthInitialization(
    username: string,
    password: string,
    authUser: AuthUser
): Promise<AuthUser> {
    if (UserType.Patient === authUser.userType) {
        return authUser;
    }
    try {
        const device = await NativeDeviceInitializer.initDevice(authUser);
        await NativeAuthManager.getInstance().storeAuthUser(username, password, authUser, device);
        OfflineSyncPlugin.syncSupportedLanguages({ userId: authUser.pk });
        return authUser;
    } catch (error) {
        if (error instanceof CIError) {
            throw error;
        }
        throw CIError.fromNativeError({
            code: NativeErrorCode.auth.UNABLE_TO_SAVE_DATA,
            message: error,
        });
    }
}

function login(
    username: string,
    password: string,
    ssoTokens?: SsoSessionKeys,
    dispatchAction?: (action: ArgumentAction<string, undefined, undefined>) => void
): Promise<AuthUser> {
    return OfflineManager.getInstance()
        .ready()
        .then(() => {
            if (OfflineManager.getInstance().isPhoneOffline()) {
                return NativeAuthManager.getInstance()
                    .getAuthUser(username, password)
                    .then((authUser) => {
                        // In offline login dont move to last active page
                        authUser.lastPagesOpened = [];
                        return authUser;
                    });
            } else if (ssoTokens) {
                getCognitoUser()?.signOut();
                const authUser = buildAuthUserFromSSOAccessToken(ssoTokens);
                localStorage.setItem('activeUser', JSON.stringify(authUser));
                const oktaSession = oktaUserPool.addOktaSession(authUser.auth);
                oktaUserPool.setSessionAsCurrent(oktaSession);
                return fillUserRecord(authUser).then((userRecord: AuthUser): AuthUser => {
                    localStorage.setItem('activeUser', JSON.stringify(userRecord));
                    return userRecord;
                });
            } else {
                return authenticateViaCognito(username, password, dispatchAction)
                    .then((session): AuthUser => buildAuthUserFromCognitoSession(session))
                    .then((authUser): Promise<AuthUser> => fillUserRecord(authUser))
                    .then(async (authUser: AuthUser) => {
                        if (Capacitor.isNativePlatform()) {
                            authUser.lastPagesOpened = [];
                            return performNativeAuthInitialization(username, password, authUser);
                        } else {
                            return authUser;
                        }
                    });
            }
        });
}

/**
 * * Deletes the User.lastPagesOpened, So the next Login would be clean.
 * ! Deleting just from BE. The Current Session State will contain lastPagesOpened until logout.
 * @param authUser Authenticated User
 * @returns Authenticated User
 */
function deleteLastPagesOpened(authUser: AuthUser): Promise<AuthUser> {
    if (Capacitor.isNativePlatform()) {
        return Promise.resolve(authUser);
    }
    if (!!authUser.lastPagesOpened && authUser.lastPagesOpened.length > 0) {
        return getGraphQLClient()
            .mutate<{ updateUserLastPages: Array<UserLastPage> }>({
                mutation: deleteLastPagesOpenedQuery,
                variables: {
                    pk: authUser.pk,
                },
            })
            .then(({ data }) => {
                if (data.updateUserLastPages.length === 0) {
                    return authUser;
                } else {
                    //Kibana logging
                    CILogger.getInstance().info(
                        {
                            message: `Unable to delete last opened pages for the user ${authUser.pk}`,
                        },
                        {
                            subjectId: authUser.pk,
                        }
                    );
                    throw new Error(`Unable to delete last opened pages for the user ${authUser.pk}`);
                }
            })
            .catch((err) => {
                throw CIError.fromGraphQLError(err);
            });
    } else {
        return new Promise((resolve) => {
            resolve(authUser);
        });
    }
}

function updateUsersLastLoggedInTime(authUser: AuthUser): Promise<AuthUser> {
    // TODO Offline Story for this needed, Temp fix
    if (OfflineManager.getInstance().isPhoneOffline()) {
        return Promise.resolve(authUser);
    }

    return getGraphQLClient()
        .mutate<{ updateUserLastActiveDate: boolean }>({
            mutation: updateUserLastActiveDate,
        })
        .then(({ data }) => {
            if (data.updateUserLastActiveDate) {
                return authUser;
            } else {
                //Kibana logging
                CILogger.getInstance().info(
                    {
                        message: `Unable to update last active date for the ${
                            authUser.type === UserType.Patient ? 'patient' : 'user'
                        } ${authUser.pk}`,
                    },
                    {
                        subjectId: authUser.type === UserType.Patient && authUser.pk,
                    }
                );
                throw new Error(
                    `Unable to update last active date for the ${
                        authUser.type === UserType.Patient ? 'patient' : 'user'
                    } ${authUser.pk}`
                );
            }
        })
        .catch((err) => {
            throw CIError.fromGraphQLError(err);
        });
}

function handleUserLastPasswordChange(toBeResolvedPromise: Promise<AWSCognito.CognitoUserSession>): Promise<AuthUser> {
    return toBeResolvedPromise
        .then((session): AuthUser => buildAuthUserFromCognitoSession(session))
        .then((authUser): Promise<AuthUser> => fillUserRecord(authUser))
        .then((authUser): Promise<AuthUser> => {
            return getGraphQLClient()
                .mutate<{ updateUser: AuthUser }>({
                    mutation: updateUserLastPasswordChanged,
                    variables: {
                        pk: authUser?.pk,
                        sk: authUser?.sk,
                        dateOfLastPasswordChange: dateToISOString(new Date(), true),
                        isDeleted: authUser?.isDeleted,
                        expectedVersion: authUser.version,
                    },
                })
                .then(({ data }): AuthUser => {
                    const { version, dateOfLastPasswordChange } = data.updateUser;
                    authUser.version = version;
                    authUser.dateOfLastPasswordChange = toDate(dateOfLastPasswordChange);
                    return authUser;
                });
        });
}

// * Active user changes the password
function userChangePassword(oldpass: string, newpass: string): Promise<AuthUser> {
    return handleUserLastPasswordChange(cognitoUserChangePassword(oldpass, newpass));
}

function changePassword(newpass: string): Promise<AuthUser> {
    return handleUserLastPasswordChange(cognitoChangePassword(newpass));
}

export enum AuthActionType {
    CHANGE_PASS = 'CHANGE_PASS',
    CHANGE_PASS_SUCCESS = 'CHANGE_PASS_SUCCESS',
    CHANGE_PASS_FAILURE = 'CHANGE_PASS_FAILURE',
    CREATE_PASSWORD = 'CREATE_PASSWORD',
    CREATE_PASS_SUCCESS = 'CREATE_PASS_SUCCESS',
    CREATE_PASS_FAILURE = 'CREATE_PASS_FAILURE',
    LOGIN_REQUEST = 'LOGIN_REQUEST',
    SWITCH_LOGIN_REQUEST = 'SWITCH_LOGIN_REQUEST',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_FAILURE = 'LOGIN_FAILURE',
    LOGOUT_REQUEST = 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
    POLICY_REQUEST = 'POLICY_REQUEST',
    POLICY_SUCCESS = 'POLICY_SUCCESS',
    POLICY_FAILURE = 'POLICY_FAILURE',
    IMPERSONATE_REQUEST = 'IMPERSONATE_REQUEST',
    IMPERSONATE_SUCCESS = 'IMPERSONATE_SUCCESS',
    IMPERSONATE_FAILURE = 'IMPERSONATE_FAILURE',
    CLEAR_ERROR = 'CLEAR_ERROR',
    UPDATE_ACTIVE_STUDY_SITE = 'UPDATE_ACTIVE_STUDY_SITE',
    RESET_ACTIVE_STUDY_SITE = 'RESET_ACTIVE_STUDY_SITE',
    RESET_COMPLETE = 'RESET_COMPLETE',
    HANDLE_SWITCH_USER = 'HANDLE_SWITCH_USER',
    HANDLE_SWITCH_USER_VALIDATION = 'HANDLE_SWITCH_USER_VALIDATION',
    HANDLE_SWITCH_USER_TEMP_USER = 'HANDLE_SWITCH_USER_TEMP_USER',
    SWITCH_USER_FAILED_STATUS = 'SWITCH_USER_FAILED_STATUS',
    HANDLE_PATIENT_ACCESS = 'HANDLE_PATIENT_ACCESS',
    HANDLE_PASSWORD_CHANGE_STATUS = 'HANDLE_PASSWORD_CHANGE_STATUS',
    USER_CHANGE_PASS = 'USER_CHANGE_PASS',
    USER_CHANGE_PASS_SUCCESS = 'USER_CHANGE_PASS_SUCCESS',
    USER_CHANGE_PASS_FAILURE = 'USER_CHANGE_PASS_FAILURE',
    USER_UPDATE_SECURITY_QUESTIONS = 'USER_UPDATE_SECURITY_QUESTIONS',
    TELEVISIT_REQUEST = 'TELEVISIT_REQUEST',
    TELEVISIT_THUMBNAIL_MODE = 'TELEVISIT_THUMBNAIL_MODE',
    UPDATE_PATIENT_STUDY_ID = 'UPDATE_PATIENT_STUDY_ID',
    UPDATE_PATIENT_ARM_ID = 'UPDATE_PATIENT_ARM_ID',
    ACTIVATION_PASSWORD_REQUEST = 'ACTIVATION_PASSWORD_REQUEST',
}

export const AuthActions = {
    changePasswordRequest: (newPassword): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.CHANGE_PASS,
        payload: { newPassword: trim(newPassword) },
    }),
    changePasswordSucceeded: (): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.CHANGE_PASS_SUCCESS,
    }),
    changePasswordFailed: (err): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.CHANGE_PASS_FAILURE,
        payload: err,
        error: true,
    }),
    createPasswordRequest: (newPassword): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.CREATE_PASSWORD,
        payload: { newPassword: trim(newPassword) },
    }),
    createPasswordSucceeded: (authUser, newPassword): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.CREATE_PASS_SUCCESS,
        payload: { authUser, newPassword },
    }),
    createPasswordFailed: (err): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.CREATE_PASS_FAILURE,
        payload: err,
        error: true,
    }),
    loginRequest: (username, password, ssoTokens?): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.LOGIN_REQUEST,
        payload: { username: toLower(trim(username)), password: trim(password), ssoTokens },
    }),
    switchUserLoginRequest: (username, password, ssoTokens?): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.SWITCH_LOGIN_REQUEST,
        payload: { username: toLower(trim(username)), password: trim(password), ssoTokens },
    }),
    loginSucceeded: (authUser): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.LOGIN_SUCCESS,
        payload: authUser,
    }),
    loginFailed: (err): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.LOGIN_FAILURE,
        payload: err,
        error: true,
    }),
    logoutRequest: (): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.LOGOUT_REQUEST,
    }),
    logoutSucceeded: (switchUser): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.LOGOUT_SUCCESS,
        payload: switchUser,
    }),
    policyRequest: (userData): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.POLICY_REQUEST,
        payload: userData,
    }),
    policySucceeded: (userData): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.POLICY_SUCCESS,
        payload: userData,
    }),
    policyFailed: (err): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.POLICY_FAILURE,
        payload: err,
        error: true,
    }),
    clearError: (): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.CLEAR_ERROR,
        error: false,
    }),
    updateActiveStudySite: (SiteStudy): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.UPDATE_ACTIVE_STUDY_SITE,
        payload: SiteStudy,
    }),
    resetActiveStudySite: (): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.RESET_ACTIVE_STUDY_SITE,
    }),
    resetComplete: (): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.RESET_COMPLETE,
    }),
    handleSwitchUser: (
        switchState,
        patientAccess: PatientAccess = {
            status: 'IDLE',
            currentPatientID: null,
            activationStatus: false,
            patientPK: null,
            activationCode: null,
            activityCompletionStatus: false,
            caregivers: [],
            isCaregiverUser: false,
            hasLAR: false,
        }
    ): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.HANDLE_SWITCH_USER,
        payload: switchState,
        meta: patientAccess,
    }),
    handleSwitchUserValidation: (validState): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.HANDLE_SWITCH_USER_VALIDATION,
        payload: validState,
    }),
    handleSwitchUserTempUser: (): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.HANDLE_SWITCH_USER_TEMP_USER,
    }),
    switchUserFailedStatus: (
        attemptedUserType,
        error = { error: null }
    ): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.SWITCH_USER_FAILED_STATUS,
        payload: attemptedUserType,
        meta: error,
    }),
    handlePatientAccess: (updatePatientAccess: PatientAccess): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.HANDLE_PATIENT_ACCESS,
        payload: updatePatientAccess,
    }),
    handlePasswordChangeStatus: (status): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.HANDLE_PASSWORD_CHANGE_STATUS,
        payload: status,
    }),
    userChangePasswordRequest: (oldPassword, newPassword): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.USER_CHANGE_PASS,
        payload: { oldPassword: trim(oldPassword), newPassword: trim(newPassword) },
    }),
    userChangePasswordSucceeded: (
        authUser: AuthUser,
        oldPassword: string,
        newPassword: string
    ): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.USER_CHANGE_PASS_SUCCESS,
        payload: {
            authUser,
            oldPassword,
            newPassword,
        },
    }),
    userChangePasswordFailed: (err): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.USER_CHANGE_PASS_FAILURE,
        payload: err,
        error: true,
    }),
    userUpdateSecurityQuestions: (questions): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.USER_UPDATE_SECURITY_QUESTIONS,
        payload: questions,
    }),
    updateTelevisitStatus: (data, active: boolean = false): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.TELEVISIT_REQUEST,
        payload: {
            data,
            active,
        },
    }),
    updateTelevisitThumbnailMode: (): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.TELEVISIT_THUMBNAIL_MODE,
    }),
    updatePatientStudyId: (data): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.UPDATE_PATIENT_STUDY_ID,
        payload: {
            data,
        },
    }),
    updatePatientArmId: (data): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.UPDATE_PATIENT_ARM_ID,
        payload: {
            data,
        },
    }),
    isNewPasswordRequested: (data): ArgumentAction<string, undefined, undefined> => ({
        type: AuthActionType.ACTIVATION_PASSWORD_REQUEST,
        payload: {
            data,
        },
    }),
};

export interface ActiveStudySite {
    activeStudy?: UserStudies;
    activeSite?: UserStudySite;
    activeStudySiteReady: boolean;
}

export type PatientAccess = {
    status?:
        | 'IDLE'
        | 'INPROGRESS'
        | 'SUCCESS'
        | 'PATIENT_FAILED'
        | 'PATIENT_COMPLETED'
        | 'USER_FAILED'
        | 'REGISTRATION_INPROGRESS'
        | 'REGISTRATION_SUCCESS';
    currentPatientID?: string; //Stores Patient USER ID/USER NAME
    activationStatus?: boolean;
    patientPK?: string; // Stores Patient PK for activation
    activationCode?: string;
    activityCompletionStatus?: boolean;
    caregivers?: Caregiver[];
    isCaregiverUser?: boolean;
    hasLAR?: boolean;
};

type PasswordChangeStatus = 'IDLE' | 'INPROGRESS' | 'SUCCESS' | 'FAILED';

export interface AuthState {
    activeUser: AuthUser;
    submitted: boolean;
    activeMeeting: boolean;
    thumbnailMode: boolean;
    complete: boolean;
    error: CIError;
    activeStudySite: ActiveStudySite; // For sponsor dashboard pages, only the study may be selected and the site could be null
    switchingUser: boolean;
    validSwitchUser: boolean;
    tempUser: AuthUser; //Used for storing user after create password is successful
    patientAccess: PatientAccess;
    passwordChangeStatus: PasswordChangeStatus;
    meetingDetails: {
        id: string;
        name: string;
        isAdmin: boolean;
    };
    newPasswordRequested: boolean;
}

export function getDefaultState(): AuthState {
    let defaultState: AuthState = {
        activeMeeting: false,
        thumbnailMode: false,
        activeUser: null,
        submitted: false,
        complete: false,
        error: null,
        activeStudySite: {
            activeStudy: null,
            activeSite: null,
            activeStudySiteReady: false,
        },
        switchingUser: false,
        validSwitchUser: false,
        tempUser: null,
        patientAccess: {
            status: 'IDLE',
            currentPatientID: null,
            activationStatus: false,
            patientPK: null,
            activationCode: null,
            activityCompletionStatus: false,
            caregivers: [],
            isCaregiverUser: false,
        },
        meetingDetails: {
            id: '',
            name: '',
            isAdmin: false,
        },
        passwordChangeStatus: 'IDLE',
        newPasswordRequested: false,
    };

    let activeUser = localStorage.getItem('activeUser');
    let activeStudySite = sessionStorage.getItem('activeStudySite');
    let switchingUser = localStorage.getItem('switchingUser');
    let patientAccess = localStorage.getItem('patientAccess');

    if (activeUser) {
        defaultState.activeUser = JSON.parse(activeUser);
        //@ts-ignore
        window.pendo.initialize();
    }
    if (activeStudySite) {
        defaultState.activeStudySite = JSON.parse(activeStudySite);
    }
    if (switchingUser) {
        defaultState.switchingUser = JSON.parse(switchingUser);
    }
    if (patientAccess) {
        defaultState.patientAccess = JSON.parse(patientAccess);
    }

    return defaultState;
}

function checkValidSwitchUser(activeStudySite: ActiveStudySite, user: AuthUser): boolean {
    const currentActiveStudy = activeStudySite.activeSite.studyId;
    const currentActiveSite = activeStudySite.activeSite.siteId;
    // Check the new user has access to current study and site
    if (user?.userStudies?.length > 0) {
        // Sometimes, the study ID can bring the version number, so ignore it
        const getStudy = find(user.userStudies, (e) => split(e.studyId, '#')[0] === currentActiveStudy);
        if (!isUndefined(getStudy)) {
            const getSite = find(getStudy.userStudySites, { siteId: currentActiveSite });
            // Valid user to proceed since it has an assigned site
            return !isUndefined(getSite);
        } else {
            // Invalid since user doesn't have a study
            return false;
        }
    }
    // Invalid - neither study or site are assigned to user
    return false;
}

export const authReducers = (state = getDefaultState(), action): AuthState => {
    switch (action.type) {
        case AuthActionType.HANDLE_PASSWORD_CHANGE_STATUS:
            return produce(state, (newState) => {
                newState.passwordChangeStatus = action.payload;
                return newState;
            });
        case AuthActionType.HANDLE_SWITCH_USER_VALIDATION:
            return produce(state, (newState) => {
                newState.validSwitchUser = action.payload;
                return newState;
            });
        case AuthActionType.HANDLE_SWITCH_USER:
            return produce(state, (newState) => {
                newState.switchingUser = action.payload;
                newState.patientAccess = {
                    ...action.meta,
                    // Since in the Login page we see an Activate Patient button when patient hasn't been activated
                    // and this is the default behavior for LAR caregivers, ensure to mark patient as
                    // activated to avoid showing button
                    activationStatus: action.meta.hasLAR ? true : action.meta.activationStatus,
                };
                updateSwitchUserState(newState); //Store Patient Access State in localStorage
                return newState;
            });
        case AuthActionType.HANDLE_PATIENT_ACCESS:
            return produce(state, (newState) => {
                const passedPatientAccess = action.payload;
                const patientAccess: PatientAccess = {
                    ...state.patientAccess,
                    ...passedPatientAccess,
                };
                newState.patientAccess = patientAccess;
                updateSwitchUserState(newState); //Update Patient Access State in localStorage
                return newState;
            });
        case AuthActionType.RESET_ACTIVE_STUDY_SITE:
            return produce(state, (newState) => {
                newState.activeStudySite.activeStudySiteReady = false;
                return newState;
            });
        case AuthActionType.HANDLE_SWITCH_USER_TEMP_USER:
            return produce(state, (newState) => {
                newState.tempUser = null;
                return newState;
            });
        case AuthActionType.UPDATE_ACTIVE_STUDY_SITE:
            return produce(state, (newState) => {
                const selectedActiveStudySite: ActiveStudySite = {
                    activeStudy: action.payload.study,
                    activeSite: action.payload.site,
                    activeStudySiteReady: true,
                };
                newState.activeStudySite = selectedActiveStudySite;
                updateActiveStudySite(newState);
                CIAnalytics.getInstance().identifyUser(state.activeUser);
                return newState;
            });
        case AuthActionType.LOGIN_REQUEST:
            return produce(state, (newState): void => {
                newState.submitted = true;
                newState.complete = false;
                newState.error = null;
            });
        case AuthActionType.SWITCH_LOGIN_REQUEST:
            return produce(state, (newState): void => {
                if (state.patientAccess.status !== 'IDLE') {
                    // Reset the patient status to INPROGRESS State
                    // This can only happen if patient already tried once and get failed
                    // * Would not be set for normal switch user flow
                    newState.patientAccess.status = 'INPROGRESS';
                }
                newState.submitted = true;
                newState.complete = false;
                newState.error = null;
            });
        case AuthActionType.LOGIN_SUCCESS:
            return produce(state, (newState): void => {
                let site;
                let authUser: AuthUser = action.payload;
                const { securityQuestion1Text, securityQuestion2Text, securityQuestion3Text } = authUser;
                const userStatus = get(authUser, 'status', null);
                const isSecurityQuestionSetUp =
                    (isNil(securityQuestion1Text) || isNil(securityQuestion2Text) || isNil(securityQuestion3Text)) &&
                    userStatus === 'Active'
                        ? false
                        : true;
                set(authUser, 'isSecurityQuestionSetUp', isSecurityQuestionSetUp);
                localStorage.removeItem('sessionLastActivity');
                authUser.sessionId = uuid();
                newState.activeUser = authUser;
                newState.error = null;

                // Do this only if the user is logging in from /login page
                // Don't need to change activeStudySite when user is loggin in from switch user dialog
                if (state.switchingUser !== true) {
                    let selectedActiveStudySite: ActiveStudySite = {
                        activeStudy: null,
                        activeSite: null,
                        activeStudySiteReady: false,
                    };
                    // If User has one Study and one Site, then make them as activeStudy and activeSite
                    const userStudies = authUser.userStudies.length > 0 ? authUser.userStudies : [];
                    if (userStudies.length === 1) {
                        const study = find(authUser.userStudies, { studyId: userStudies[0].studyId });
                        const sites = study.userStudySites;
                        const userHasToSelectSiteForStudy = !isSponsorUser(authUser);

                        // If the user has 1 site or comes from a handshake, let's assign one site by default
                        // so the active study/site flow is bypassed and we can redirect the user to a different
                        // landing page. In those landing pages, the proper information will be set, specially for
                        // Patient Chart, since we have all the information we needs
                        if (
                            (authUser.userType !== AuthUserType.PATIENT &&
                                sites?.length > 0 &&
                                getCookie(OrionCookies.oktaHandshake)) ||
                            sites?.length === 1
                        ) {
                            // Single Site
                            site = find(study.userStudySites, { siteId: study.userStudySites[0].siteId });
                            selectedActiveStudySite = {
                                activeStudy: study,
                                activeSite: site,
                                activeStudySiteReady: true,
                            };
                        } else if (!userHasToSelectSiteForStudy && sites?.length > 1) {
                            //A sponsor-type user doesn't have to specify a site for a study. If there's at least one we can go to sponsor dashboard
                            selectedActiveStudySite = {
                                activeStudy: study,
                                activeSite: null,
                                activeStudySiteReady: true,
                            };
                        }
                    }
                    // Fill with one study/site until the real one (from patient chart or study config) is set
                    // in the proper landing page
                    else if (
                        userStudies.length > 0 &&
                        authUser.userType !== AuthUserType.PATIENT &&
                        getCookie(OrionCookies.oktaHandshake)
                    ) {
                        const study = find(authUser.userStudies, { studyId: userStudies[0].studyId });

                        // Single Site
                        site = find(study.userStudySites, { siteId: study.userStudySites[0].siteId });
                        selectedActiveStudySite = {
                            activeStudy: study,
                            activeSite: site,
                            activeStudySiteReady: true,
                        };
                    }
                    newState.activeStudySite = selectedActiveStudySite;
                }

                newState.submitted = false;
                newState.complete = true;

                //Setting state here to indicate for login page
                newState.validSwitchUser = !isNil(authUser.isValidSwitchUser) && authUser.isValidSwitchUser;
                if (isNil(state?.switchingUser) || state.switchingUser === false) {
                    newState.switchingUser = false;
                }

                persistAuthUsers(newState);
                updateSwitchUserState(newState); //persist switch user modal state
                if (authUser.userType === AuthUserType.PATIENT) {
                    // Study Audit logging
                    let patientStudyID;
                    let patientSiteID;
                    if (newState.activeUser.userStudies !== null && newState.activeUser.userStudies.length !== 0) {
                        patientStudyID = newState.activeUser.userStudies[0].studyId;
                        if (includes(patientStudyID, '#')) {
                            patientStudyID = split(patientStudyID, '#')[0];
                        }
                        if (
                            newState.activeUser.userStudies[0].userStudySites !== null &&
                            newState.activeUser.userStudies[0].userStudySites.length !== 0
                        ) {
                            patientSiteID = newState.activeUser.userStudies[0].userStudySites[0].siteId;
                        }
                    }
                    CIAuditLogger.getInstance().log(
                        AuditCreationEventsEnum.Login,
                        `Patient ${newState.activeUser.username} logged into Patient Web`,
                        null,
                        {
                            relatedEntityName: AuditEntityIdEnum.User,
                            relatedEntityId: newState.activeUser.username,
                            studyId: patientStudyID,
                            siteId: patientSiteID,
                            subjectId: newState.activeUser.pk,
                        }
                    );
                    //Kibana logging
                    CILogger.getInstance().info(
                        {
                            message: `Patient user ${newState.activeUser.pk} logged into Patient Web.`,
                        },
                        {
                            studyId: patientStudyID,
                            siteId: patientSiteID,
                            subjectId: newState.activeUser.pk,
                        }
                    );
                } else {
                    CIAuditLogger.getInstance().log(
                        AuditCreationEventsEnum.Login,
                        `User ${newState.activeUser.username} logged in${
                            state.switchingUser ? ' by switch user.' : ''
                        }`,
                        null,
                        {
                            relatedEntityName: AuditEntityIdEnum.User,
                            relatedEntityId: newState.activeUser.pk,
                        }
                    );
                }
                // Added for audit log event on successfull login
            });
        case AuthActionType.LOGIN_FAILURE:
            return produce(state, (newState): void => {
                newState.submitted = false;
                newState.complete = false;
                newState.error = action.payload;
            });
        case AuthActionType.LOGOUT_REQUEST:
            return produce(state, (newState): void => {
                newState.submitted = true;
                newState.complete = false;
                newState.error = null;
            });
        case AuthActionType.RESET_COMPLETE:
            return produce(state, (newState): void => {
                newState.complete = false;
            });
        case AuthActionType.LOGOUT_SUCCESS:
            const { switchUser } = action.payload;

            return produce(state, (newState): void => {
                if (get(state, 'activeUser.userType', '') === AuthUserType.PATIENT) {
                    // Study Audit logging
                    let patientStudyID;
                    let patientSiteID;
                    if (
                        get(state, 'activeUser.userStudies', null) !== null &&
                        get(state, 'activeUser.userStudies.length', 0) !== 0
                    ) {
                        patientStudyID = get(state, 'activeUser.userStudies[0].studyId', null);
                        if (patientStudyID !== null && includes(patientStudyID, '#')) {
                            patientStudyID = split(patientStudyID, '#')[0];
                        }
                        if (
                            get(state, 'activeUser.userStudies[0].userStudySites', null) !== null &&
                            get(state, 'activeUser.userStudies[0].userStudySites.length', 0) !== 0
                        ) {
                            patientSiteID = get(state, 'activeUser.userStudies[0].userStudySites[0].siteId', null);
                        }
                    }
                    CIAuditLogger.getInstance().log(
                        AuditCreationEventsEnum.Logout,
                        `Patient user ${newState.activeUser.username} logged out of Patient Web`,
                        null,
                        {
                            relatedEntityName: AuditEntityIdEnum.User,
                            relatedEntityId: newState.activeUser.username,
                            studyId: patientStudyID,
                            siteId: patientSiteID,
                            subjectId: newState.activeUser.pk,
                        }
                    );

                    //Kibana logging
                    CILogger.getInstance().info(
                        {
                            message: `Patient ${state.activeUser.pk} logout of Patient Web.`,
                        },
                        {
                            studyId: patientStudyID,
                            siteId: patientSiteID,
                            subjectId: state.activeUser.pk,
                        }
                    );
                } else {
                    //Kibana logging
                    CILogger.getInstance().info({
                        message: `${state.activeUser?.username} logged out ${
                            state.switchingUser ? 'by switch user.' : ''
                        }`,
                    });
                }

                //If switch user flag is ON then don't remove the active user
                newState.activeUser = switchUser ? state.activeUser : null;
                newState.submitted = false;
                //make the complete false to enable second try on switch user modal
                newState.complete = state.switchingUser ? false : true;
                //Switch User - If already opened dont close
                newState.switchingUser = state.switchingUser ? true : false;

                //Clear active Study Site only if its a normal logout
                if (!state.switchingUser) {
                    // activeStudySiteReady to true here because to prevent redirection from dashboard to ActiveStudySite Screen
                    // during logout
                    newState.activeStudySite = {
                        activeStudy: null,
                        activeSite: null,
                        activeStudySiteReady: true,
                    };
                }

                const activeUserFromSession = localStorage.getItem('activeUser');
                const activeUser: AuthUser = activeUserFromSession && JSON.parse(activeUserFromSession);

                newState.patientAccess.status = switchUser ? state.patientAccess.status : 'IDLE';
                newState.patientAccess.currentPatientID = switchUser ? state.patientAccess.currentPatientID : null;
                newState.patientAccess.caregivers = switchUser ? state.patientAccess.caregivers : [];
                updateAuthUsers(newState, state.switchingUser);

                if (activeUser?.authenticationMethod === 'OKTA') {
                    setCookie(OrionCookies.oktaLogout, 'false', 1);
                    oktaUserPool.removeSession();
                    oktaSLO(activeUser.auth.idToken);
                }

                if (!state.switchingUser) {
                    getCognitoUser()?.signOut();
                    localStorage.removeItem('activeUser');
                }
            });
        case AuthActionType.POLICY_REQUEST:
            return produce(state, (newState): void => {
                newState.submitted = true;
                newState.complete = false;
                newState.error = null;
            });
        case AuthActionType.POLICY_SUCCESS:
            return produce(state, (newState): void => {
                const { activeUser } = action.payload;
                newState.activeUser = activeUser;
                newState.submitted = false;
                newState.complete = true;
                if (!newState.activeUser.isSecurityQuestionSetUp) {
                    newState.activeUser.isSecurityQuestionSetUp = true;
                }
                persistAuthUsers(newState);
            });
        case AuthActionType.POLICY_FAILURE:
            return produce(state, (newState): void => {
                newState.submitted = false;
                newState.complete = false;
                newState.error = action.payload;
            });
        case AuthActionType.CHANGE_PASS_SUCCESS:
            return produce(state, (newState): void => {
                newState.submitted = false;
                newState.complete = false;
                // removing the error from auth to avoid showing it on login page after successful pwd change
                if (state.error?.message === 'cognito.PasswordUnavailable') {
                    newState.error = null;
                } else {
                    newState.error = { ...state.error, trigger: null };
                }
            });
        case AuthActionType.CHANGE_PASS_FAILURE:
            return produce(state, (newState): void => {
                newState.submitted = false;
                newState.complete = false;
                newState.error = action.payload;
            });
        case AuthActionType.CREATE_PASS_SUCCESS:
            return produce(state, (newState): void => {
                const { authUser } = action.payload as { authUser: AuthUser; newPassword: string };
                authUser.sessionId = uuid();

                if (state.switchingUser) {
                    //Storing user in tempUser state because during switch user there is already a user in activeUser state.
                    newState.tempUser = authUser;
                } else {
                    newState.activeUser = authUser;
                }

                newState.submitted = false;
                newState.complete = false;
                // removing the error from auth to avoid showing it on login page after successful pwd change
                if (state.error?.message === 'cognito.PasswordUnavailable') {
                    newState.error = null;
                } else {
                    newState.error = { ...state.error, trigger: null };
                }
            });
        case AuthActionType.CREATE_PASS_FAILURE:
            return produce(state, (newState): void => {
                newState.submitted = false;
                newState.complete = false;
                newState.error = action.payload;
            });
        case AuthActionType.USER_CHANGE_PASS_SUCCESS:
            return produce(state, (newState): void => {
                const { authUser, oldPassword, newPassword } = action.payload as {
                    authUser: AuthUser;
                    oldPassword: string;
                    newPassword: string;
                };
                authUser.sessionId = uuid();
                newState.activeUser = authUser;

                if (state.passwordChangeStatus !== 'IDLE') {
                    newState.passwordChangeStatus = 'SUCCESS';
                }
                newState.submitted = false;
                newState.complete = false;
                // removing the error from auth to avoid showing it on login page after successful pwd change
                if (state.error?.message === 'cognito.PasswordUnavailable') {
                    newState.error = null;
                } else {
                    newState.error = { ...state.error, trigger: null };
                }

                // Update Econsent Signature
                updateEconsentSignature(authUser.pk, oldPassword, newPassword);

                persistAuthUsers(newState);
            });
        case AuthActionType.USER_CHANGE_PASS_FAILURE:
            return produce(state, (newState): void => {
                if (state.passwordChangeStatus !== 'IDLE') {
                    newState.passwordChangeStatus = 'FAILED';
                }
                newState.submitted = false;
                newState.complete = false;
                newState.error = action.payload;
            });
        case AuthActionType.CLEAR_ERROR:
            return produce(state, (newState): void => {
                newState.error = null;
            });
        case AuthActionType.SWITCH_USER_FAILED_STATUS:
            const userType = action.payload;
            return produce(state, (newState): void => {
                if (state.patientAccess.status === 'INPROGRESS') {
                    const { error } = action.meta;
                    if (error) {
                        newState.patientAccess.status = error;
                    } else {
                        newState.patientAccess.status = userType === 'Patient' ? 'PATIENT_FAILED' : 'USER_FAILED';
                    }
                }
                newState.submitted = false;
                newState.complete = true;
                newState.validSwitchUser = false;
            });
        case AuthActionType.USER_UPDATE_SECURITY_QUESTIONS:
            const { securityQuestion1Text, securityQuestion2Text, securityQuestion3Text } = action.payload;
            return produce(state, (newState): void => {
                newState.activeUser.securityQuestion1Text = securityQuestion1Text;
                newState.activeUser.securityQuestion2Text = securityQuestion2Text;
                newState.activeUser.securityQuestion3Text = securityQuestion3Text;
                persistAuthUsers(newState);
            });
        case AuthActionType.TELEVISIT_REQUEST:
            const { data, active } = action.payload;
            return produce(state, (newState): void => {
                newState.activeMeeting = active;
                newState.meetingDetails.id = data.id || '';
                newState.meetingDetails.name = data.name || '';
                newState.meetingDetails.isAdmin = data.isAdmin || false;
                if (!active) {
                    newState.thumbnailMode = false;
                }
            });
        case AuthActionType.TELEVISIT_THUMBNAIL_MODE:
            return produce(state, (newState): void => {
                newState.thumbnailMode = !state.thumbnailMode;
            });
        case AuthActionType.UPDATE_PATIENT_STUDY_ID:
            return produce(state, (newState): void => {
                const studyId = action.payload.data;
                newState.activeUser.studyId = studyId;
                newState.activeUser.userStudies[0].studyId = studyId;
                if (newState.activeStudySite.activeStudy.studyId) {
                    newState.activeStudySite.activeStudy.studyId = studyId;
                }
                if (newState.activeStudySite.activeSite.studyId) {
                    newState.activeStudySite.activeSite.studyId = studyId;
                }
                persistAuthUsers(newState);
            });
        case AuthActionType.UPDATE_PATIENT_ARM_ID:
            return produce(state, (newState): void => {
                const armId = action.payload.data;
                newState.activeUser.armId = armId;
                persistAuthUsers(newState);
            });
        case AuthActionType.ACTIVATION_PASSWORD_REQUEST:
            return produce(state, (newState): void => {
                newState.newPasswordRequested = action.payload.data;
            });
        default:
            return state;
    }
};

const LoginLogic = createLogic({
    type: AuthActionType.LOGIN_REQUEST,
    process({ action }, dispatch, done): void {
        // @ts-ignore
        const { username, password, ssoTokens } = action.payload;
        login(username, password, ssoTokens, dispatch)
            .then((authUser) => deleteLastPagesOpened(authUser))
            .then((authUser) => updateUsersLastLoggedInTime(authUser))
            .then((authUser): void => {
                setLanguage(authUser?.preferredLanguage || 'en-US');
                // to avoid showing guides in policy page, check if the current policy and user accepted policy are same
                if (
                    (authUser.currentPolicy?.[0] &&
                        authUser.acceptedVersionPP === authUser.currentPolicy[0].majorVersion) ||
                    !includes(authUser.permissions, 'SubscriberPP_Read')
                ) {
                    CIAnalytics.getInstance().identifyUser(authUser);
                }
                dispatch(AuthActions.loginSucceeded(authUser));
            })
            .catch((err): void => {
                dispatch(AuthActions.loginFailed(err));
            })
            .finally(done);
    },
});

const SwitchUserLoginLogic = createLogic({
    type: AuthActionType.SWITCH_LOGIN_REQUEST,
    process({ getState, action }, dispatch, done): void {
        // @ts-ignore
        const { username, password, ssoTokens } = action.payload;

        login(username, password, ssoTokens)
            .then((authUser) => deleteLastPagesOpened(authUser)) // If the Second user authentication is success then delete the lastPagesOpened
            .then((authUser) => updateUsersLastLoggedInTime(authUser))
            .then((authUser): void => {
                const { auth } = getState() as { auth: AuthState };

                // Check Valid User
                const validSwitchUser = checkValidSwitchUser(auth.activeStudySite, authUser);

                let siteActivityAccess = false;

                // * Not Equal to IDLE will prevent patient from entering from normal switch user popups
                // * If they enter they will get `Not assigned to study sites` error for now

                if (auth.patientAccess.status !== 'IDLE') {
                    const userRoleType = authUser?.roleVClaim?.name;
                    if (authUser.userType === UserType.Patient) {
                        // If username is associated with a caregiver list, then match it to allow proceeding with login
                        // This will only happen when switching users
                        const caregivers = auth.patientAccess?.caregivers || [];
                        if (
                            (auth.patientAccess?.isCaregiverUser &&
                                caregivers.length > 0 &&
                                !find(caregivers, ['name', username])) ||
                            (!auth.patientAccess?.isCaregiverUser &&
                                caregivers.length > 0 &&
                                find(caregivers, ['name', username]))
                        ) {
                            resetLastAuthUser(auth.activeUser.pk, authUser.pk);
                            dispatch(
                                AuthActions.loginFailed({
                                    translationKey: 'cognito.NotAuthorizedException',
                                    sourceError: {
                                        code: 'NotAuthorizedException',
                                        name: 'NotAuthorizedException',
                                    },
                                    code: 'NotAuthorizedException',
                                })
                            );
                            done();
                            return;
                        }

                        siteActivityAccess = authUser.username === auth.patientAccess.currentPatientID;
                        // Check if this valid patient already completed the activity
                        if (siteActivityAccess && auth.patientAccess.activityCompletionStatus) {
                            resetLastAuthUser(auth.activeUser.pk, authUser.pk);
                            dispatch(
                                AuthActions.switchUserFailedStatus(authUser.userType, {
                                    error: 'PATIENT_COMPLETED',
                                })
                            );
                            return;
                        }
                    } else if (
                        userRoleType !== RoleTypeEnum.SiteInvestigator &&
                        userRoleType !== RoleTypeEnum.SiteCoordinator
                    ) {
                        resetLastAuthUser(auth.activeUser.pk, authUser.pk);
                        // Disable access to the activity if the user is not Site related Roles
                        dispatch(AuthActions.switchUserFailedStatus(authUser.userType, { error: 'PATIENT_FAILED' }));
                        return;
                    }
                }

                if (validSwitchUser || siteActivityAccess) {
                    setLanguage(authUser?.preferredLanguage || 'en-US');
                    //Allowing patients, initialize pendo only when has already accepted privacy policy in switch user flow and for user who does not have to accept policy
                    if (
                        (authUser.currentPolicy?.[0] &&
                            authUser.acceptedVersionPP === authUser.currentPolicy[0].majorVersion) ||
                        !includes(authUser.permissions, 'SubscriberPP_Read')
                    ) {
                        CIAnalytics.getInstance().identifyUser(authUser);
                    }
                    dispatch(AuthActions.logoutSucceeded({ switchUser: true }));
                    //Setting valid Switch user prop to AuthUser to pass information to switch user modal
                    authUser.isValidSwitchUser = true;
                    //If same User entered again via switch user no need to replace LastAuthUser of Cognito
                    if (authUser.pk !== auth.activeUser.pk) {
                        resetLastAuthUser(authUser.pk, auth.activeUser.pk);
                    }
                    dispatch(AuthActions.loginSucceeded(authUser));
                } else {
                    resetLastAuthUser(auth.activeUser.pk, authUser.pk);
                    dispatch(AuthActions.switchUserFailedStatus(authUser.userType));
                }
            })
            .catch((err): void => {
                dispatch(AuthActions.loginFailed(err));
            })
            .finally(done);
    },
});

const LogoutLogic = createLogic({
    type: AuthActionType.LOGOUT_REQUEST,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    process({ action }, dispatch, done): void {
        dispatch(AuthActions.logoutSucceeded({ switchUser: false }));
        CIAnalytics.getInstance().clearSession();
        setTimeout(() => {
            sessionStorage.clear();
            done();
        }, 50);
    },
});

/**
 * Responsible for creating password for new users
 */
const ChangePassLogic = createLogic({
    type: AuthActionType.CHANGE_PASS,
    process({ getState, action }, dispatch, done): void {
        // @ts-ignore
        const { newPassword } = action.payload;
        changePassword(newPassword)
            .then((authUser): void => {
                if (authUser?.type !== AuthUserType.PATIENT) {
                    markPasswordUsed(authUser?.pk, newPassword);
                }
                CIAnalytics.getInstance().identifyUser(authUser);

                // Should not login the new user in Patient Activation/Registration Flow
                // Store the successful user in the tempUser to handle it accordingly
                //@ts-ignore
                const { auth }: { auth: AuthState } = getState();
                if (auth.patientAccess.status !== 'IDLE') {
                    dispatch(AuthActions.createPasswordSucceeded(authUser, newPassword));
                } else {
                    dispatch(AuthActions.loginSucceeded(authUser));
                }
            })
            .catch((err): void => {
                dispatch(AuthActions.changePasswordFailed(err));
            })
            .finally(done);
    },
});

const UserChangePassLogic = createLogic({
    type: AuthActionType.USER_CHANGE_PASS,
    process({ action }, dispatch, done): void {
        // @ts-ignore
        const { oldPassword, newPassword } = action.payload;
        userChangePassword(oldPassword, newPassword)
            .then((authUser): void => {
                if (authUser?.type !== AuthUserType.PATIENT) {
                    markPasswordUsed(authUser?.pk, newPassword);
                }
                dispatch(AuthActions.userChangePasswordSucceeded(authUser, oldPassword, newPassword));
            })
            .catch((err): void => {
                dispatch(AuthActions.userChangePasswordFailed(err));
            })
            .finally(done);
    },
});

const CreatePassLogic = createLogic({
    type: AuthActionType.CREATE_PASSWORD,
    process({ action }, dispatch, done): void {
        // @ts-ignore
        const { newPassword } = action.payload;
        changePassword(newPassword)
            .then((authUser): void => {
                CIAnalytics.getInstance().identifyUser(authUser);
                if (authUser?.type !== AuthUserType.PATIENT) {
                    markPasswordUsed(authUser?.pk, newPassword);
                }
                dispatch(AuthActions.createPasswordSucceeded(authUser, newPassword));
            })
            .catch((err): void => {
                dispatch(AuthActions.createPasswordFailed(err));
            })
            .finally(done);
    },
});

const PolicyLogic = createLogic({
    type: AuthActionType.POLICY_REQUEST,
    process({ action }, dispatch, done): void {
        // @ts-ignore
        const data = action.payload;
        const { activeUser } = data;
        getGraphQLClient()
            .mutate({
                mutation: updateUserToSPP,
                variables: {
                    userId: activeUser?.pk,
                    pVersion: activeUser.currentPolicy?.[0]?.majorVersion,
                },
            })
            .then((): void => {
                dispatch(AuthActions.policySucceeded(data));
            })
            .catch((err): void => {
                dispatch(AuthActions.policyFailed(err));
            })
            .then(() => done());
    },
});

export const ReduxLogic = [
    LoginLogic,
    ChangePassLogic,
    PolicyLogic,
    UserChangePassLogic,
    CreatePassLogic,
    LogoutLogic,
    SwitchUserLoginLogic,
];

/**
 * For unit test use only
 */
export let UnderTest = {
    buildAuthUserFromCognitoSession,
    mapGraphQLToUser,
    AuthActions,
    authReducers,
    AuthActionType,
    checkValidSwitchUser,
    mapGraphQLToPatient,
};
